.card {
    margin-top: 20px;
    height: 260px;
    border: initial;
    border-radius: 20px;
    box-shadow: 0 8px 24px hsla(210, 8%, 62%, .2);
    color: black;
    background: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
}

.display {
    margin-top: 10px;
    width: 360px;
    height: 220px;
    border-radius: 20px;
    background-color: black;
    padding: 10px 5px;
}

.display div {
    height: 180px;
    /* width: 300px; */
    background: white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.display div h3 {
    margin: 0;
}

.display p {
    margin: inherit;
    color: white;
}

.displayContent {
    margin: 0px 20px;
}

.displayContent h2 {
    margin: 10px 0px 0px 0px;
}

.displayContent p {
    text-align: start;
    margin: 0;
}