.profile-main-container {
    padding: 20px;
}

.profile-container {
    background: #FFFFFF;
    box-shadow: -5px 4px 14.7px 0px #0000001A;
    padding: 24px;
    border-radius: 15px;
    margin-top: 20px;
}

.profile-photo-box {
    display: flex;
    gap: 20px;
}

.profile-picture-text {
    font-size: 30px;
    font-weight: 500;
    line-height: 91.15px;
    color: #252525;
    margin: 0;
    text-align: start;
}

.upload-button-container {
    display: flex;
    gap: 10px;
}

.upload-button {
    background-color: rgba(13, 110, 253, 1);
    padding: 15px 25px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: rgb(253, 253, 253);
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    display: flex;
    gap: 10px;
    opacity: 0.5;
}

.remove-button {
    background-color: transparent;
    padding: 15px 25px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 2px solid black;
    display: flex;
    gap: 15px;
    opacity: 0.5;
}

.profile-title {
    font-size: 60px;
    font-weight: 500;
    line-height: 91.15px;
    color: #252525;
    margin: 0;
    margin-top: 80px;
    text-align: start;
}

.profile-subtext {
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    color: #536172;
    margin: 0;
    border-bottom: 1px solid #536172;
    padding-bottom: 18px;
    text-align: start;
}

.profile-picture-desc {
    font-size: 14px;
    font-weight: 500;
    color: #949494;
    text-align: start;
}

.profile-form-section {
    margin-top: 50px;
}

.change-password-button {
    background-color: transparent;
    padding: 24px 22px 24px 22px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: #0D6EFD;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1px solid #0D6EFD;
    display: flex;
    gap: 15px;
    width: 48%;
    justify-content: center;
}

.save-button-container {
    display: flex;
    gap: 10px;
    margin-top: 30px;
    width: 50%;
}

.save-button {
    width: 48%;
}

.password-field {
    display: grid;
    position: relative;
    margin-top: 5px;
}


.password-field input {
    display: block;
    padding: 24px 22px 24px 22px;
    margin: 5px 0;
    border-radius: 15px;
    border: 1px solid rgba(226, 232, 240, 1);
    box-sizing: border-box;
    /* background-color: rgba(237, 239, 241, 0.932); */
    font-size: 18px;
    color: rgba(83, 97, 114, 1);
}

.password-field img {
    position: absolute;
    padding-top: 63px;
    right: 25px;
    width: 18px;
    height: 18px;
}

.password-field label {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(37, 37, 37, 1);
    margin-top: 10px;
    text-align: start;
}

.password-field input:focus,
.password-field input:focus-visible {
    border: 1px solid rgba(98, 98, 98, 1);
    background-color: rgba(0, 0, 0, 0.04);
    outline: initial;
}


.error-message {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: rgba(255, 0, 0, 0.6);
    margin: 0;
    margin-top: 25px;
}

.error-border {
    border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.input-container {
    display: flex;
    gap: 2%;
}

@media only screen and (max-width: 720px) {
    .profile-photo-box {
        flex-direction: column;
        gap: 0;
    }

    .save-button-container {
        width: 100%;
        flex-direction: column;
    }

    .input-container {
        flex-direction: column;
    }

    .change-password-button {
        width: 100%;
    }

    .save-button {
        width: 100%;
    }

    .profile-form-section {
        margin: 0;
    }
}