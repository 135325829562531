#header {
    position: fixed;
    width: 15vw;
}

#header .pro-sidebar {
    height: 100vh;
}

#header .closemenu {
    color: rgb(206, 219, 228);
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
}

#header .pro-sidebar {
    width: 100%;
    min-width: 100%;
}

#header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
}

#header .pro-sidebar-inner {
    /* background-color: rgb(72, 136, 189); */
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0px 20px 20px 0px;
    background: #1E1E1E;
}

#header .pro-sidebar-inner .pro-sidebar-layout {
    /* overflow-y: hidden; */
}

#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
    font-size: 20px;
    padding: 0 20px;
    color: rgb(240, 244, 247);
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 5px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: rgb(206, 219, 228);
    margin: 10px 0px;
    font-weight: bold;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color: transparent;
    color: rgb(242, 246, 248);
    border-radius: 3px;
}

#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: rgb(206, 219, 228);
}

.pro-sidebar .pro-menu a {
    color: white;
}

.disableText {
    color: #7F7F7F !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    color: black !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active a {
    color: black !important;
}

#header .pro-sidebar-inner .pro-sidebar-layout .active .pro-inner-item .pro-icon-wrapper {
    filter: invert(100%);
}

#header .logo {
    padding: 20px;
}

.logotext>img {
    height: 60px;
    width: 175px;
    object-fit: contain;
    padding: 10px 0;
}

.active-link {
    background-color: #f0f0f0;
    /* Example background color */
    color: #333;
    /* Example text color */
    /* Add any other styles you want to apply to active links */
}

/* Define styles for active link icons */
.active-link svg {
    /* Example styling for active link icons */
    fill: #333;
    /* Example icon color */
    /* Add any other styles you want to apply to active link icons */
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 22px;
    padding: 20px;
    box-sizing: border-box;
}

.modal-title {
    font-size: 40px;
    font-weight: 700;
    color: rgba(37, 37, 37, 1);
    margin: 0;
    text-align: center;
}

.modal-desc {
    font-size: 20px;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.8);
    margin: 0;
    text-align: center;
}


.remove-button {
    background-color: transparent;
    padding: 15px 25px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: black;
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 2px solid black;
    display: flex;
    gap: 15px;
    opacity: 0.5;
}

.humburger-icon {
    display: none;
}

@media only screen and (max-width: 720px) {
    html {
        overflow: hidden;
    }

    .humburger-icon {
        display: flex;
        padding: 40px 20px 0px;
    }
}