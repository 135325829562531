.heading {
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: rgba(37, 37, 37, 1);
    margin: 0;
}

.breadcrumbs {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding: 5px 10px;
    display: flex;
    margin: 5px 0;
    width: fit-content;
}

.breadcrumbs p {
    font-size: 12px;
    font-weight: 300;
    margin: 0;
    cursor: pointer;
}

.breadcrumbs span {
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    cursor: pointer;
}

.button-container {
    display: flex;
    gap: 10px;
}

.dashboard-body {
    /* display: flex; */
    align-items: start;
    margin-top: 20px;
}

.screen-container {
    box-sizing: border-box;
    width: 23%;
    height: 175px;
    border-radius: 20px;
    box-shadow: -5px 4px 14.7px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    padding: 16px 25px;
    background-color: rgba(255, 255, 255, 1);
}

.box-heading {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    color: rgba(37, 37, 37, 1);
}

.screen-number {
    font-size: 24px;
    font-weight: 500;
    line-height: 29.26px;
    color: rgba(37, 37, 37, 1);
    text-align: justify;
}

.screen-date {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    color: rgba(37, 37, 37, 1);
    text-align: start;
}

.screen-pertantage {
    font-size: 10.28px;
    font-weight: 400;
    margin: 0;
    margin-top: 20px;
    color: rgba(0, 222, 163, 1);
    text-align: justify;
}

.screen-layout {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: end;
}

.submitBtn {
    width: 100%;
}

.StatisticsBox {
    box-sizing: border-box;
    width: 30%;
    height: 622px;
    padding: 10px;
    border-radius: 21px;
    border: 0.5px 0px 0px 0px;
    /* opacity: 0px; */
    background-color: rgba(255, 255, 255, 1);
    margin: 25px 30px 0;
}

.mediafile-main-box {
    height: 174px;
    padding: 12px;
    border-radius: 23px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: -5px 4px 14.7px 0px rgba(0, 0, 0, 0.1);
}

.mediafiles-sub-box {
    box-sizing: border-box;
    height: 100%;
    padding: 12px;
    border-radius: 15px;
    background-color: rgba(236, 236, 236, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.totalmediaText {
    font-size: 16px;
    font-weight: 500;
    color: rgba(37, 37, 37, 1);
    margin: 0;
}

.totalmediaNumber {
    font-size: 45px;
    font-weight: 600;
    color: rgba(13, 110, 253, 1);
    margin: 0;
}

.totalmediaVideos {
    font-size: 10px;
    font-weight: 400;
    color: rgba(37, 37, 37, 1);
    margin: 0;
}

.statisticsSmallBox {
    margin-top: 20px;
    /* width: 289px; */
    height: 350px;
    border-radius: 21px;
    background: rgba(255, 255, 255, 1);
    padding: 20px;
    box-shadow: -3.61px 2.89px 10.62px 0px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.media-chart-container {
    width: 280px;
    height: 100%;
    display: flex;
    justify-content: center;
}

.total-mb {
    font-size: 12px;
    font-weight: 400;
    color: rgba(37, 37, 37, 0.6);
}

.total-mb span {
    font-size: 24px;
    font-weight: 600;
    color: rgba(13, 110, 253, 1);
}

.stat-small-box-text {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.dashboard-table-container {
    padding: 24px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    margin: 25px 20px;
    width: calc(100% - 40px);
    height: max-content;
    box-sizing: border-box;
}

.dashboard-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 2%;
}

.screen-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
}

.screen-header-right-side {
    display: flex;
    gap: 10px;
}

.screen-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    color: rgba(0, 0, 0, 1);
    margin: 0;
    text-align: justify;
}

.total-screen-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.6);
    margin: 5px 0 0;
}

.showing-text {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(0, 0, 0, 0.6);
    text-align: justify;
}

.dashboard-activity-container {
    padding: 30px;
}

.activity-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 20px;
}

.account-box {
    display: flex;
    gap: 10px;
    padding: 30px 20px 20px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.account-text {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    text-align: start;
}

.subaccount-text {
    font-size: 18px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
    margin: 0;
    text-align: start;
    margin-top: 5px;
}

.dashboard-screentable-container {
    display: flex;
}

.allocate-space-text {
    font-size: 24px;
    font-weight: 500;
    line-height: 12.19px;
}

.allocate-space-text span {
    font-size: 10px;
    font-weight: 500;
    line-height: 12.19px;
    color: rgba(0, 38, 28, 0.3);
}


.overlay {
    height: 0%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgba(237, 237, 237, 0.5);
    overflow-y: hidden;
    transition: 0.5s;
}

.overlay.open {
    height: 100%;
}

.overlay-content {
    position: relative;
    top: 120px;
    width: 100%;
    right: 27px;
    text-align: end;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: end;
}

.overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay a:hover,
.overlay a:focus {
    color: #f1f1f1;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
}

.add-button {
    width: 35px;
    height: 35px;
    background: rgba(0, 0, 0, 1);
    color: white;
    padding: 4px;
    border-radius: 50%;
    display: none;
}

@media only screen and (max-width: 720px) {
    .screen-container {
        width: 100%;
    }

    /* .screen-header {
        flex-direction: column;
    } */

    .screen-header-right-side {
        flex-direction: column;
    }

    .button-container {
        flex-direction: column;
        display: none;
    }

    .screen-text {
        font-size: 14px;
    }

    .total-screen-text {
        font-size: 10px;
        line-height: 15px;
        margin: 0;
    }

    .dashboard-card-container {
        gap: 10px;
    }

    .dashboard-screentable-container {
        flex-direction: column;
    }

    .StatisticsBox {
        box-sizing: border-box;
        width: 100%;
        margin: 10px 0;
    }

    .overlay {
        overflow-y: auto;
    }

    .overlay a {
        font-size: 20px;
    }

    .overlay .closebtn {
        font-size: 27px;
        top: 70px;
        right: 27px;
    }

    .add-button {
        display: block;
    }

    .account-text {
        font-size: 16px;
    }

    .subaccount-text {
        font-size: 12px;
    }
}