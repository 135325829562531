.add-group-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 22px;
    padding: 20px;
    box-sizing: border-box;
}

.group-card {
    width: 200px;
    height: 200px;
    padding: 10px 13px;
    gap: 145.12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2.9px 2.32px 8.53px 0px rgba(0, 0, 0, 0.1);
}

.group-card-title {
    font-size: 17px;
    font-weight: 600;
    margin: 0;
}

.playlist-group-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
    overflow: auto;
    gap: 20px;
    padding: 10px 0;
}

.playlist-group-card {
    width: 200px;
    height: 160px;
    padding: 10px 13px;
    gap: 145.12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2.9px 2.32px 8.53px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}

.playlist-checkbox {
    position: absolute !important;
    top: 10px;
    right: 10px;
}

.select-playlist-text {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    margin: 0;
}

.playlist-group-card-title {
    font-size: 12px;
    font-weight: 700;
    text-align: start;
    margin: 10px 0;
}

.edit-group-card-content-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.edit-group-card-title {
    font-size: 17px;
    font-weight: 600;
    text-align: start;
    margin: 0;
}

.edit-group-card-date-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    gap: 3px;
}

.schedule-btn {
    background: #F4F900;
    width: fit-content;
    padding: 5px 30px;
    border-radius: 75px;
    border: none;
    color: black;
}

.schedule-form {
    height: calc(80vh - 202px);
}

.publish-screen-desc {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    margin: 0;
    color: #252525;
}

@media only screen and (max-width: 720px) {
    .add-group-modal-container {
        width: 100%;
        /* flex-direction: column; */
        gap: 10px;
    }

    .schedule-form {
        height: 70%;
    }
}