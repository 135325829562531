.container {
    /* display: grid; */
    justify-content: center;
    background-image: url("../Assets/login-gradient.svg");
    background-repeat: no-repeat;
    height: 100vh;
    background-color: #041643;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: contain;
    gap: 10px;
}

.sub-login-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.div1 {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin-left: 100px;

}

.loginform {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginform-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.loginform {
    width: 550px;
    height: 450px;
    padding: 0px 30px;
    gap: 51px;
    border-radius: 10px;
    /* opacity: 0px; */
    background-color: rgba(255, 255, 255, 1);
}

.login-left-text {
    font-family: Nunito Sans;
    font-size: 48px;
    font-weight: 800;
    line-height: 54.14px;
    text-align: left;
    color: #FFFFFF;
    margin: 0;
}

.login-left-subtext {
    font-size: 21px;
    font-weight: 400;
    line-height: 29.54px;
    text-align: left;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
}

.term-container {
    display: flex;
    gap: 20px;
}

.term-condition-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: rgba(154, 160, 168, 1);
}

.field {
    display: grid;
    position: relative;
    margin-top: 5px;
    text-align: center;
    width: 540px;
    height: 70px;

}

.field input {
    display: block;
    padding: 24px 22px 24px 22px;
    margin: 5px 0;
    border-radius: 15px;
    border: 1px solid rgba(226, 232, 240, 1);
    box-sizing: border-box;
    /* background-color: rgba(237, 239, 241, 0.932); */
    font-size: 18px;
    color: rgba(83, 97, 114, 1);
}

.field input:focus,
.field input:focus-visible {
    border: 1px solid rgba(98, 98, 98, 1);
    background-color: rgba(0, 0, 0, 0.04);
    outline: initial;
}

.error-message {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: rgba(255, 0, 0, 0.6);
    margin: 0;
    margin-top: 25px;
}

.error-border {
    border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.forgot-password {
    color: rgba(13, 110, 253, 1);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

.btn12 {
    background-color: rgba(13, 110, 253, 1);
    padding: 24px 22px 24px 22px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: rgb(253, 253, 253);
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
}

.btn12:hover {
    /* transform: translateY(-3px); */
    background-color: rgba(13, 110, 253, 0.9);
    ;
    transition: all 0.2s;
}

.btn12:disabled {
    /* transform: translateY(-3px); */
    background-color: rgba(13, 110, 253, 0.5);
    cursor: not-allowed;
    transition: all 0.2s;
}

#btn2 {
    background-color: rgba(13, 94, 132, 1);
    width: 330px;
}

#btn2:hover {
    transform: translateY(-3px);
    background-color: rgb(6, 61, 86);
    transition: all 0.2s;
}

#account {
    color: #000;
    text-align: center;
    font-size: 15px;
    padding-right: 20px;
    text-decoration: none;
}

#l4 {
    color: #000;
    text-align: center;
    font-size: 15px;
    padding: 40px 0 25px 0;
}

.field img {
    position: absolute;
    padding-top: 54px;
}

.field label {
    justify-self: left;
    font-weight: 500;
    padding-left: -10px;
}

.middle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #000;
    margin: 5px 0 15px 0;
}

.right label div {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.forgot-password-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forgot-password-box {
    box-sizing: border-box;
    width: 1100px;
    height: 600px;
    padding: 50px;
    gap: 51px;
    border-radius: 30px;
    /* opacity: 0px; */
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: space-between;
}

.text-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
}

.image-container {
    width: 50%;
    height: 100%;
}

.image-container img {
    width: 100%;
    height: 100%;
}

.forgot-password-text {
    font-size: 57.29px;
    font-weight: 600;
    line-height: 63.65px;
    text-align: left;
    color: rgba(0, 0, 0, 1);
    margin: 0;
}

.forgot-password-subtext {
    font-size: 25.46px;
    font-weight: 400;
    line-height: 31.04px;
    text-align: left;
    color: rgba(0, 0, 0, 0.4);
    margin: 0;
}

.forgot-password-subtext span {
    color: rgba(13, 110, 253, 1);
}

.logo {
    height: 80px;
    width: 350px;
}

@media screen and (max-width: 500px) {
    .container {
        display: block;
        float: none;
        /* margin-left: 15px;
        margin-right: 15px; */
        padding-top: 1px;
        padding-bottom: 20px;
    }

    .container h1 {
        margin-top: 1px;
        font-size: 25px;
    }

    .field {
        justify-content: center;
        margin-top: 1px;
    }

    .field input {
        width: 270px;
        padding: 14px 12px;
    }

    .middle {
        font-size: small;
        justify-content: center;
        display: grid;
        margin-top: -10px;
    }

    .field img {
        position: absolute;
        padding-top: 54px;
        padding-left: 68px;
        width: 18px;
        height: 18px;
    }

    .error-message {
        margin-top: 10px;
    }

    .right label a {
        justify-content: end;
    }

    button a {
        font-size: small;
    }

    .btn1 {
        width: 195px;
    }

    #l4 {
        font-size: small;
    }

    .container p {
        text-align: center;
    }

    .sub-login-container {
        flex-direction: column;
    }

    .logo {
        height: 50px;
        width: 200px;
        text-align: start;
    }

    .div1 {
        margin-left: 10px;
        width: 100%;
        align-items: start;
    }

    .login-left-text {
        font-size: 32px;
        line-height: 40px;
        text-align: start !important;
        margin-top: 20px;
    }

    .login-left-subtext {
        font-size: 16px;
        text-align: start !important;
    }

    .loginform-container {
        width: 100%;
    }

    .loginform {
        width: 340px;
        height: 440px;
        padding: 0 10px;
        margin-top: 20px;
    }

    .field {
        width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .container {
        display: block;
        float: none;
        margin-left: 5px;
        margin-right: 5px;
    }

    .field {
        justify-content: center;
    }

    .field input {
        width: 220px;
    }

    .middle {
        font-size: small;
        justify-content: center;
        display: grid;
    }

    .field img {
        position: absolute;
        padding-top: 38px;
        padding-left: 92px;
        width: 18px;
        height: 18px;
    }

    .right label a {
        justify-content: end;
    }

    button a {
        font-size: small;
    }

    .btn1 {
        width: 180px;
    }

    .btn12 {
        width: 195px;
    }

    #l4 {
        font-size: small;
    }

    .container h1 {
        text-align: center;
        font-size: 25px;
    }
}