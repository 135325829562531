.file-header {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-top: 50px;
}

.file-header-right-side {
    display: flex;
    gap: 10px;
}

.file-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 29.26px;
    color: rgba(0, 0, 0, 1);
    margin: 0;
    text-align: justify;
}

.total-file-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    color: rgba(0, 0, 0, 0.6);
    margin: 5px 0 0;
}

.mediaFileCard {
    width: 203px;
    height: 220px;
    padding: 10px 13px;
    gap: 145.12px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2.9px 2.32px 8.53px 0px rgba(0, 0, 0, 0.1);
}

.mediaFileCardImg {
    width: 100%;
    height: 120px;
    border-radius: 7px;
}

.medialCard-header {
    font-size: 13px;
    font-weight: 600;
    text-align: justify;
    margin: 0;
    color: rgba(45, 55, 72, 1);
}

.medialCard-desc {
    font-size: 10px;
    font-weight: 400;
    text-align: justify;
    margin: 5px 0;
    color: rgba(45, 55, 72, 0.6);
}

.medialCard-date {
    font-size: 10px;
    font-weight: 400;
    text-align: justify;
    margin: 0;
    color: rgba(45, 55, 72, 0.6);
}

.medialCard-date span {
    color: rgba(13, 110, 253, 1);
}

.rename-btn {
    background: rgba(13, 110, 253, 1);
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: white;
    cursor: pointer;
}

.mediafile-button-container {
    display: flex;
    gap: 5px;
    margin-top: 3px;
}

.mediafile-button-container img {
    height: 25px;
    cursor: pointer;
}

.mediafile-not-found {
    font-size: 38px;
    font-weight: 600;
    justify-self: center;
    width: 100%;
    color: rgba(0, 0, 0, 0.6);

}

.delete-modal-btn {
    background-color: rgba(239, 67, 67, 1);
    padding: 24px 22px 24px 22px;
    border-radius: 15px;
    border-style: none;
    cursor: pointer;
    color: rgb(253, 253, 253);
    font-size: 20px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
}

.delete-modal-btn:hover {
    /* transform: translateY(-3px); */
    background-color: rgba(239, 67, 67, 0.8);
    transition: all 0.2s;
}

.delete-modal-btn:disabled {
    /* transform: translateY(-3px); */
    background-color: rgb(198 128 128);
    transition: all 0.2s;
}

.upload-media-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.upload-file-container {
    width: 100%;
    height: 262px;
    padding: 24px;
    border-radius: 12.53px;
    border: 2px dashed rgba(31, 70, 144, 1);
    background: rgba(0, 78, 225, 0.08);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: auto;
}

.modal-title-upload-file {
    font-size: 24px;
    font-weight: 500;
    color: rgba(37, 37, 37, 1);
    margin: 0;
    align-self: flex-start;
}

.modal-desc {
    font-size: 20px;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.8);
    margin: 0;
    text-align: center;
}


.modal-desc span {
    font-size: 24px;
    font-weight: 500;
    color:
        rgba(13, 110, 253, 1);
    margin: 0;
    text-align: center;
}

.drop-img-title {
    font-size: 16.71px;
    font-weight: 400;
    color: rgba(19, 42, 0, 1);
    margin: 10px 0;
}

.drop-img-title span {
    font-weight: 500;
    color: rgba(31, 70, 144, 1);
    cursor: pointer;
}

.drop-img-desc {
    font-size: 12px;
    font-weight: 400;
    color: rgba(150, 157, 178, 1);
    margin: 0;
}

.folderCard {
    width: 203px;
    height: 220px;
    padding: 10px 13px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -2.9px 2.32px 8.53px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media only screen and (max-width: 720px) {
    .file-header {
        flex-direction: column;
    }

    .mediaFileCard {
        width: 100%;
    }
}