.input-field input {
    display: block;
    padding: 24px 22px 24px 22px;
    margin: 5px 0;
    border-radius: 15px;
    border: 1px solid rgba(226, 232, 240, 1);
    box-sizing: border-box;
    /* background-color: rgba(237, 239, 241, 0.932); */
    font-size: 18px;
    color: rgba(83, 97, 114, 1);
    width: 100%;
}

.input-field label {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(37, 37, 37, 1);
    margin-top: 10px;
    text-align: justify;
}

.input-field input:focus,
.input-field input:focus-visible {
    border: 1px solid rgba(98, 98, 98, 1);
    background-color: rgba(0, 0, 0, 0.04);
    outline: initial;
}

.input-error-message {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: rgba(255, 0, 0, 0.6);
    margin: 0;
    margin: 5px 0;
}

.input-error-border {
    border: 1px solid rgba(255, 0, 0, 0.6) !important;
}

.form-container {
    overflow-y: auto;
}

.submitBtn {
    width: 100%;
}

.screen-table-container {
    padding: 24px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 1);
    margin-top: 25px;
    width: 100%;
    box-sizing: border-box;
}

.wrapper {
    position: relative;
}

.wrapper-screen {
    position: relative;
}

.icon {
    height: 1.5rem;
    width: 1.5rem;
    padding: 4px;
    position: absolute;
    box-sizing: border-box;
    top: 44%;
    left: 5px;
    transform: translateY(-50%);
}

.search-input {
    width: 220px;
    height: 20px;
    padding: 10px;
    padding-left: 35px;
    border-radius: 20px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: -5px 4px 14.7px 0px rgba(0, 0, 0, 0.1);
    border: none;
}


.search-input:focus,
.search-input:focus-visible {
    border: 1px solid rgba(98, 98, 98, 1);
    outline: initial;
}

.header-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-time-text {
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 1);
}

.header-date-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
}

.header-profile-box {
    display: flex;
    align-items: center;
}

.header-username-text {
    font-size: 14px;
    font-weight: 400;
    color: rgba(2, 61, 85, 1);
}

.header-profile-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 22px;
    padding: 20px;
    box-sizing: border-box;
}

.modal-title {
    font-size: 40px;
    font-weight: 700;
    color: rgba(37, 37, 37, 1);
    margin: 0;
    text-align: center;
}

.modal-desc {
    font-size: 20px;
    font-weight: 500;
    color: rgba(37, 37, 37, 0.8);
    margin: 0;
    text-align: center;
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.screen-card-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 2%;
}

.tableScroll {
    overflow: auto;
}

.add-drawer {
    height: 100%;
    width: 550px;
    overflow: auto;
}

@media only screen and (max-width: 720px) {
    .screen-card-container {
        width: 100%;
        flex-direction: column;
        gap: 10px;
    }

    .header-container {
        display: none;
    }

    .wrapper-screen {
        display: none;
    }

    .smallHide {
        display: none !important;
    }
}

.input-label {
    box-sizing: border-box;
    font-size: 18px;
    color: rgba(37, 37, 37, 1);
    margin: 0;
    text-align: justify;
    padding: 5px 0;
}