.parentDiv {
    display: flex;
    height: calc(100vh - 75px);
    overflow-x: hidden;
    background-color: rgba(240, 249, 254, 1);
}

.playlistDiv {
    width: 340px;
    height: calc(100%);
    border-radius: 0px 30px 0px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: -3px 4px 11.7px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
}

.zoneDiv {
    width: 340px;
    height: calc(100%);
    border-radius: 30px 0px 0px 0px;
    overflow-x: hidden;
    overflow-y: auto;
    box-shadow: -3px 4px 11.7px 0px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 1);
}

.TvscreenDiv {
    width: calc(100% - 680px);
    height: calc(100%);
}

.button {
    /* width: "9px"; */
    background: '#3379d0';
    height: "35px";
    color: 'white';
    border-radius: '10px';
}

#zoneDiv {
    width: "24%";
    height: 'calc(100% - 10px)';
    background: 'white';
    margin: '5px';
    border-radius: '10px';
    box-shadow: '0px 0px 5px gray';
}

.playlist-content-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-top: 3px;
    align-items: start;
}

.playlist-button-container {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    margin-top: 15px;
    align-items: start;
}

.playlist-topbar-container {
    display: flex;
    justify-content: space-between;
    background-color: rgba(44, 44, 44, 1);
    height: 75px;
    align-items: center;
}

.playlist-topbar-container p {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    color: rgba(255, 255, 255, 1);
    margin-left: 35px;
}

.zone-card-container {
    display: flex;
    justify-content: space-between;
}

.zone-card-title {
    font-size: 12px;
    font-weight: 500;
    color: rgba(45, 45, 45, 1);
    margin: 10px 0 0;
    text-align: start;
}

.zone-card-desc {
    font-size: 10px;
    font-weight: 400;
    color: rgba(160, 174, 192, 1);
    margin-top: 5px;
    text-align: start;
}

.zone-card-delete-icon {
    margin-top: 10px;
    cursor: pointer;
}

.select-section-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.select-section-box p {
    color: rgba(160, 174, 192, 1);
    font-size: 16px;
    font-weight: 500;
}

.add-playlist-modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 10px;
    padding: 20px;
    box-sizing: border-box;
}

.yellow-contained-btn {
    background-color: rgba(252, 163, 17, 1);
    padding: 8px 40px;
    border-radius: 21px;
    border-style: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(252, 163, 17, 1);
}

.yellow-outlined-btn {
    background-color: transparent;
    padding: 8px 40px;
    border-radius: 21px;
    border-style: none;
    cursor: pointer;
    color: rgba(252, 163, 17, 1);
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(252, 163, 17, 1);
}

.blue-contained-btn {
    background-color: rgba(13, 110, 253, 1);
    padding: 8px 40px;
    border-radius: 21px;
    border-style: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(13, 110, 253, 1);
}

.blue-outlined-btn {
    background-color: transparent;
    padding: 8px 40px;
    border-radius: 21px;
    border-style: none;
    cursor: pointer;
    color: rgba(13, 110, 253, 1);
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(13, 110, 253, 1);
}

.white-contained-btn {
    background-color: rgba(255, 255, 255, 1);
    padding: 8px 40px;
    border-radius: 11px;
    border-style: none;
    cursor: pointer;
    color: rgba(13, 110, 253, 1);
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(255, 255, 255, 1);
}

.white-contained-btn:disabled {
    opacity: 0.5;
}

.white-outlined-btn {
    background-color: transparent;
    padding: 8px 40px;
    border-radius: 11px;
    border-style: none;
    cursor: pointer;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    border: 1.33px solid rgba(255, 255, 255, 1);
}

.tv-screen-container {
    box-shadow: -4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    height: 440px;
    width: 660px;
    display: flex;
    flex-direction: column;
}

.youtube-widget-logo {
    height: 80px;
    width: 150px;
    box-shadow: -2.39px 1.91px 7.03px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.select-file-btn {
    background-color: rgba(0, 0, 0, 1);
    padding: 10px 40px;
    border-radius: 11px;
    border-style: none;
    cursor: pointer;
    color: white;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
}

.music-mute-container {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;
    box-shadow: -4.39px 3.51px 12.9px 0px rgba(0, 0, 0, 0.1);
    padding: 10px;
    width: calc(100% - 20px);
    border-radius: 8px;
}

.music-mute-container p {
    margin: 0;
}

/* Customizing the dropdown container */
.dropdown-button {
    border-radius: 20px !important;
    color: #ffffff;
    font-family: 'Arial', sans-serif;
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;
    background: #ffffff !important;
}

/* Customizing the dropdown options */
.font-picker__option {
    padding: 8px 12px;
    /* Padding for the dropdown options */
    cursor: pointer;
    /* Change cursor to pointer */
    font-size: 14px;
    /* Font size for options */
}

/* Hover effect for dropdown options */
.font-picker__option:hover {
    background-color: #61dafb;
    /* Blue background on hover */
    color: #282c34;
    /* Dark text color on hover */
}

/* Selected option styling */
.font-picker__option--selected {
    background-color: #61dafb;
    /* Blue background for selected option */
    color: #282c34;
    /* Dark text color for selected option */
    font-weight: bold;
    /* Bold text for selected option */
}

/* Styling the dropdown arrow */
.font-picker__dropdown-arrow {
    color: #61dafb;
    /* Blue arrow */
}

.weatherZoneCard {
    height: 75px;
    width: 100%;
    border: 1px solid black;
    box-shadow: 0px 0px 5px 2px grey;
    border-radius: 5px;
    background: white;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.weatherTvScreenCard {
    height: 100%;
    width: 100%;
    background: #cccccc;
    flex-direction: column;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-widget-icon {
    margin-top: 5px;
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    .playlist-topbar-container {
        flex-direction: column;
        height: 220px;
        justify-content: center;
        align-items: center;
    }

    .tv-screen-container {
        width: calc(100% - 30px);
        height: 350px;
    }

    .parentDiv {
        flex-direction: column;
        height: calc(100vh - 220px);
        overflow: auto;
    }

    .playlistDiv {
        width: 100%;
        overflow: unset;
        height: auto;
    }

    .TvscreenDiv {
        width: 100%;
    }

    .zoneDiv {
        width: 100%;
        overflow: unset;
    }
}